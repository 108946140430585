import React from "react";
type LeftHeaderProps = {
  children?: React.ReactNode;
  title?: string;
  image?: string;
};

export const LeftHeader = ({ children, title,image }: LeftHeaderProps) => {
  return (
    <div className="leftBox" style={{backgroundImage:`url(${image})`,backgroundSize:"cover"}}>
      <h3 className="leftBox__header">{title}</h3>
    </div>
  );
};
