import { Formik } from 'formik';
import { Link } from 'gatsby';
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import 'yup-phone';

type ContactDetailsProps = {
  setAllowNext: (value: boolean) => void;
  reservationData: any;
  setReservationData: (value: object) => void;
};

export const ContactDetails = ({
  reservationData,
  setAllowNext,
  setReservationData,
}: ContactDetailsProps) => {
  useEffect(() => {
    if (
      reservationData.contact.name != '' &&
      reservationData.contact.surname != '' &&
      reservationData.contact.phone != '' &&
      reservationData.contact.email != '' &&
      reservationData.contact.terms
    ) {
      setAllowNext(true);
    }
    return () => setAllowNext(false);
  }, [reservationData]);

  // (e) => ({
  //   ...e,
  //   contact: { ...e.contact, [name]: inputData.target.value }
  // })

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Proszę podać poprawne imię')
      .required('To pole jest wymagane'),
    surname: Yup.string()
      .min(2, 'Proszę podać poprawne nazwisko')
      .required('To pole jest wymagane'),
    phone: Yup.string()
      .phone('', true, 'Proszę podać numer w formacie +48451062970')
      .required('To pole jest wymagane'),
    email: Yup.string()
      .email('Proszę podać poprawny adres email')
      .required('To pole jest wymagane'),
    terms: Yup.bool().oneOf([true], 'Proszę zaakceptować warunki'),
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ ...reservationData.contact, terms: false }}
      validateOnChange={true}
      onSubmit={(values, formikHelpers) => {
        setReservationData((e) => ({
          ...e,
          contact: values,
        }));
      }}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        errors,
        touched,
        submitForm,
        setFieldValue,
      }) => {
        useEffect(() => {
          if (
            Object.keys(errors).length === 0 &&
            Object.keys(touched).length > 0
          ) {
            submitForm();
          } else {
            setAllowNext(false);
          }
        }, [errors]);

        return (
          <form onSubmit={handleSubmit} className="contactDetails">
            {[
              { name: 'name', title: 'Imię' },
              { name: 'surname', title: 'Nazwisko' },
              { name: 'phone', title: 'Numer telefonu' },
              { name: 'email', title: 'Adres e-mail' },
            ].map(({ name, title }) => (
              <div>
                <div className="input">
                  <input
                    placeholder={title}
                    name={name}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors[name] && <p className={'validation'}>{errors[name]}</p>}
              </div>
            ))}

            <label className="checkbox__label">
              <Checkbox
                checked={values['terms']}
                name={'terms'}
                onBlur={handleBlur}
                onChange={() => {
                  setFieldValue('terms', !values['terms']);
                }}
              />
              <div style={{ paddingLeft: '1rem' }}>
                Wyrażam zgodę na przetwarzanie moich danych w celu nawiązania
                kontaktu i odpowiedzi na moje zapytanie. Zapoznałem/am się i
                akceptuję{' '}
                <Link to="/polityka_prywatnosci">Politykę prywatności</Link>
              </div>
            </label>
          </form>
        );
      }}
    </Formik>
  );
};
