import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import dayjs from "dayjs";
import calendarArrow from "./../../../assets/images/calendar-arrow.svg";
import "dayjs/locale/pl";

type SelectDateProps = {
  reservationData: object;
  setAllowNext: (value: boolean) => void;
  setReservationData: (value: object) => void;
};

export const SelectDate = ({
  reservationData,
  setAllowNext,
  setReservationData,
}: SelectDateProps) => {
  const [value, onChange] = useState(dayjs().toDate());
  dayjs.locale("pl");

  useEffect(() => {
    if (reservationData.date !== "") {
      setAllowNext(true);
    }
    return () => setAllowNext(false);
  }, [reservationData]);

  useEffect(() => {
    setReservationData((e: object) => ({ ...e, date: value }));
  }, [value]);

  const goNextMonth = () => {
    onChange(dayjs(value).add(1, "month").toDate());
  };

  const goPrevMonth = () => {
    onChange(dayjs(value).subtract(1, "month").toDate());
  };

  const hasPrevMonth = () => {
    // @ts-ignore
    const now = dayjs().format("YYMM") * 1;
    // @ts-ignore
    const given = dayjs(value).format("YYMM") * 1;
    return now < given;
  };

  const PrevButton = () => {
    return (
      <img
        className={`react-calendar__navigation__arrow__prev-button-icon`}
        src={calendarArrow}
        alt={"prev-arrow"}
      />
    );
  };

  const NextButton = () => {
    return (
      <img
        className={`react-calendar__navigation__arrow__next-button-icon`}
        src={calendarArrow}
        alt={"next-arrow"}
      />
    );
  };

  return (
    <div className={"selectDate"}>
      <Calendar
        onChange={onChange}
        value={value}
        minDetail={"month"}
        minDate={dayjs().toDate()}
        prevLabel={<PrevButton />}
        nextLabel={<NextButton />}
        prev2Label={null}
        next2Label={null}
      />
    </div>
  );
};
