import React, { useEffect, useState } from "react";
import { Layout } from "../components/utilityComponents/layout";
import { Container } from "../helpers/container";
import { LeftHeader } from "../components/reservationPageComponents/leftHeader";
import headerImage from "../assets/images/rezerwuj.jpg";
import { Button } from "../components/utilityComponents/button";
import { SelectDate } from "../components/reservationPageComponents/steps/selectDate";
import { SelectPersons } from "../components/reservationPageComponents/steps/selectPersons";
import { SelectTime } from "../components/reservationPageComponents/steps/selectTime";
import { ContactDetails } from "../components/reservationPageComponents/steps/contactDetails";
import { Confirmation } from "../components/reservationPageComponents/steps/confirmation";
import arrowLeft from "../assets/images/arrow-left.svg";
import { useWidth } from "../hooks/useWidth";

export default function ReservationPage() {
  const [step, setStep] = useState<number>(0);
  const [hideNext, setHideNext] = useState(false);
  const [hidePrev, setHidePrev] = useState(false);
  const [reservationData, setReservationData] = useState<object>({
    date: "",
    time: "",
    persons: 0,
    contact: {
      name: "",
      surname: "",
      phone: "",
      email: "",
      terms: false
    }
  });
  const [allowNext, setAllowNext] = useState(false);
  const { width } = useWidth();
  const prevStep = () => {
    setStep((e) => e - 1);
  };

  const nextStep = () => {
    setStep((e) => e + 1);
  };

  useEffect(() => {
  }, [allowNext]);

  const steps = [
    {
      title: "Data",
      component: (
        <SelectDate
          reservationData={reservationData}
          setAllowNext={setAllowNext}
          setReservationData={setReservationData}
        />
      )
    },
    {
      title: "Ilość osób",
      component: (
        <SelectPersons
          reservationData={reservationData}
          setStep={setStep}
          setAllowNext={setAllowNext}
          setReservationData={setReservationData}
          setHideNext={setHideNext}
          setHidePrev={setHidePrev}
        />
      )
    },
    {
      title: "Godzina",
      component: (
        <SelectTime
          reservationData={reservationData}
          setAllowNext={setAllowNext}
          setReservationData={setReservationData}
        />
      )
    },
    {
      title: "Dane kontaktowe",
      component: (
        <ContactDetails
          reservationData={reservationData}
          setReservationData={setReservationData}
          setAllowNext={setAllowNext}
        />
      )
    },
    {
      component: (
        <Confirmation
          reservationData={reservationData}
          allowNext={allowNext}
          setAllowNext={setAllowNext}
          setReservationData={setReservationData}
        />
      )
    }
  ];

  const isPrev = () => {
    return step > 0;
  };
  const isNext = () => {
    return step < steps.length;
  };
  const isLast = () => {
    return step === steps.length - 1;
  };

  console.log(process.env.GATSBY_API_URL," <><<AA")


  return (
    <Layout smallNavbar href="#IWT">
      <Container className="reservation">
        <LeftHeader title={`Rezerwacja stolika`} image={headerImage} />
        <div className={"leftBox--line"}></div>
        <div className={"reservation"}>
          <main className={"reservation__wrapper"}>
            {steps[step].title && (
              <div className={"reservation__header"}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h3 className="reservation__header--text headerH01">
                    WYBIERZ
                  </h3>
                </div>
                {width > 1200 ? (
                  <div className={"reservation__upperNav"}>
                    <button
                      className={`reservation__upperNav--prevArrow reservation__upperNav--arrow  ${
                        !isPrev() && "reservation__upperNav--disabled"
                      }`}
                      disabled={!isPrev()}
                      onClick={() => {
                        if (isPrev()) {
                          prevStep();
                        }
                      }}
                    >
                      <img src={arrowLeft} alt={"arrow-left"} />
                    </button>
                    <div className={"reservation__upperNav--innerSection"}>
                      {steps[step - 1] && steps[step - 1].title}
                    </div>
                    <div className={"reservation__upperNav--activeSection"}>
                      {steps[step].title}
                    </div>
                    <div className={"reservation__upperNav--innerSection"}>
                      {steps[step + 1] && steps[step + 1].title}
                    </div>
                    <button
                      className={`reservation__upperNav--nextArrow reservation__upperNav--arrow ${
                        !allowNext || !isNext()
                          ? "reservation__upperNav--disabled"
                          : ""
                      }`}
                      disabled={!allowNext}
                      onClick={() => {
                        if (isNext() && allowNext) {
                          nextStep();
                        }
                      }}
                    >
                      <img src={arrowLeft} alt={"arrow-right"} />
                    </button>
                  </div>
                ) : (
                  <div className={"reservation__upperNav--mobile"}>
                    {steps
                      .filter(({ title }) => title)
                      .map(({ title }, key) => {
                        return (
                          <p
                            className={`reservation__upperNav--mobileText${
                              steps[step].title == title ? "Active" : ""
                            }`}
                            key={key}
                            onClick={() => {
                              if (key < step) {
                                setStep(key);
                              }
                            }}
                          >
                            {title}
                          </p>
                        );
                      })}
                  </div>
                )}
              </div>
            )}

            <div className={"reservation__main"}>{steps[step].component}</div>

            <div className={"reservation__lowerNav"}>
              {/*{(step <= 3 && (!hideNext) && isNext() && !isLast()) ? (*/}
              {hidePrev || isLast() ? <div /> : isPrev() ? (
                <Button
                  label={"Wstecz"}
                  size={"medium"}
                  type={"secondary"}
                  className={"reservation__lowerNav--prevButton"}
                  onClick={prevStep}
                />
              ) : (
                <div></div>
              )}
              {isLast() && (
                <Button
                  label={"Strona główna"}
                  size={"medium"}
                  type={"primary"}
                  disabled={!allowNext}
                  onClick={() => {
                    setStep(0);
                  }}
                />
              )}
              {(step <= 3 && (!hideNext) && isNext() && !isLast()) ? (
                <Button
                  label={"Dalej"}
                  size={"medium"}
                  type={"primary"}
                  className={`reservation__lowerNav--nextButton${
                    !allowNext ? "Disabled" : ""
                  }`}
                  disabled={!allowNext}
                  onClick={() => {
                    if (allowNext) {
                      nextStep();
                    }
                  }}
                />
              ) : (
                <div></div>
              )}
            </div>
          </main>
        </div>
      </Container>
    </Layout>
  );
}
