import React, { useEffect } from 'react'
import { gql, useMutation } from '@apollo/client'
import loader from './../../../assets/images/loader.svg'
import confirmationBook from './../../../assets/images/confirmation-book.png'
import dayjs from 'dayjs'

type ConfirmationProps = {
  reservationData: any
  allowNext: boolean
  setAllowNext: (value: boolean) => void
  setReservationData: (value: object) => void
}

export const Confirmation = ({
  reservationData,
  setAllowNext,
  setReservationData,
  allowNext,
}: ConfirmationProps) => {
  const [mutateFunction, { data, loading, error }] = useMutation(gql`
    mutation createReservation(
      $key: String!
      $dateTime: DateTime!
      $peoples: Int!
      $name: String!
      $surname: String!
      $phone: String!
      $email: String!
      $date: DateTime!
    ) {
      createReservation(
        data: {
          peopleQty: $peoples
          name: $name
          surname: $surname
          phone: $phone
          email: $email
          requestDateTime: $dateTime
          key: $key
          publishedAt: $date
        }
      ) {
        data {
          attributes {
            peopleQty
          }
        }
      }
    }
  `)
  console.log(reservationData)

  useEffect(() => {
    const dateTime =
      dayjs(reservationData.date).format('YYYY-MM-DD') +
      'T' +
      reservationData.time +
      ':00Z'
    mutateFunction({
      variables: {
        dateTime: dateTime,
        peoples: reservationData.persons,
        name: reservationData.contact.name,
        surname: reservationData.contact.surname,
        phone: reservationData.contact.phone,
        email: reservationData.contact.email,
        key:
          new Date().getTime().toString() +
          Math.random().toString().substring(2),
        date: dayjs().toISOString(),
      },
    })
      .then(() => {
        setReservationData({
          date: '',
          time: '',
          persons: 0,
          contact: {
            name: '',
            surname: '',
            phone: '',
            email: '',
            terms: false,
          },
        })
        setAllowNext(true)
      })
      .catch(e => {
        console.log('BŁĄD!', e)
      })
  }, [])
  return (
    <div className={'confirmation'}>
      {allowNext ? (
        <div className={'confirmation__agree'}>
          <img src={confirmationBook} alt={'confirmation'} />
          <h3>Dziękujemy za rezerwację</h3>
          <p>
            Pamiętaj, iż twoja rezerwacja nie jest jeszcze potwierdzona.
            Wyczekuj maila z potwierdzeniem rezerwacji.
          </p>
        </div>
      ) : (
        <img src={loader} alt={'loader'} />
      )}
    </div>
  )
}
