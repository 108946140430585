import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import { useWidth } from "../../../hooks/useWidth";
import phoneIcon from "./../../../assets/images/phone-icon.svg";
import { Button } from "../../utilityComponents/button";


type SelectPersonsProps = {
  reservationData: any;
  setAllowNext: (value: boolean) => void;
  setHideNext: (value: boolean) => void;
  setHidePrev: (value: boolean) => void;
  setStep: (value: boolean) => void;
  setReservationData: (value: object) => void;
};

export const SelectPersons = ({
                                reservationData,
                                setAllowNext,
                                setHideNext,
                                setHidePrev,
                                setStep,
                                setReservationData
                              }: SelectPersonsProps) => {
  const [value, setValue] = useState(null);
  const [morePersons, setMorePersons] = useState(false);
  const { width } = useWidth();
  useEffect(() => {
    if (reservationData.persons > 0) {
      setAllowNext(true);
    }
    return () => setAllowNext(false);
  }, [reservationData]);

  useEffect(() => {
    if (morePersons) {
      setReservationData((e: object) => ({ ...e, persons: 0 }));
      setAllowNext(false);
      setHideNext(true);
    } else {
      setHideNext(false);
    }
    return () => {
      setHideNext(false);
      // setHidePrev(false);
    };
  }, [morePersons]);

  useEffect(() => {
    if (value) {
      setReservationData((e: object) => ({ ...e, persons: value }));
    }
  }, [value]);

  const minPersons = 1;
  const maxPersons = 8;
  const RenderButtons = () => {
    let els = [];
    for (let i = minPersons; i <= maxPersons; i++) {
      els.push(
        <button
          className={`selectPersons__singleButton${
            reservationData.persons === i ? "--active" : ""
          }`}
          key={i}
          onClick={() => {
            setValue(i);
          }}
        >
          {i}
        </button>
      );
    }
    return els;
  };

  return (
    <StaticQuery
      query={graphql`
        query ContactPhone {
          strapiContact {
            addressDetails {
              phone
            }
          }
        }
      `}
      render={({ strapiContact: { addressDetails } }) => {
        return morePersons ? (
          <div className={"selectPersons__moreUsersContainer"}
               style={width < 1200 ? { height: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between" } : { height: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
            <div>
              <p className={"selectPersons__moreUsersContainer--strong"}>
                W przypadku większej ilości osób
              </p>
              <p className={"selectPersons__moreUsersContainer--text"}>
                Prosimy o zrezygnowanie z rezerwacji online, oraz o kontakt
                telefoniczny.
              </p>

              <a
                href={`tel:${addressDetails.phone}`}
                className={"selectPersons__moreUsersContainer--phone"}
              >
                <img src={phoneIcon} alt={"phone-icon"} />
                {addressDetails.phone}
              </a>
            </div>
            <div style={width > 1200 ? { display:"flex" } : {}}>
              <Button
                label={width < 1200 ? "Powrót" : "Wstecz"}
                size={"medium"}
                style={width < 1200 ? { width: "100%" } : {}}
                type={"secondary"}
                onClick={() => {
                  setHidePrev(false);
                  setMorePersons(false);
                }}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className={"selectPersons"}>
              <RenderButtons />
            </div>
            <p
              className={"selectPersons__moreUsers"}
              onClick={() => {
                setHidePrev(true);
                setHideNext(true);
                setMorePersons(true);
              }}
            >
              Większa ilość osób?
            </p>
          </div>
        );
      }}
    />
  );
};
